<template>
    <el-dialog title="拉黑详情" width="70%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="" :column="2">
                <el-descriptions-item label="姓名">{{info.ticketingVisitorUser}}</el-descriptions-item>
                <el-descriptions-item label="证件类型">{{info.ticketingCardType}}</el-descriptions-item>
                <el-descriptions-item label="证件号码">{{info.ticketingVisitorCard}}</el-descriptions-item>
                <el-descriptions-item label="拉黑时间">{{info.pullBlackTime}}</el-descriptions-item>
                <el-descriptions-item label="释放时间">{{info.releaseBlackTime}}</el-descriptions-item>
                <el-descriptions-item label="拉黑操作人">{{info.pullBlackUser}}</el-descriptions-item>
                <el-descriptions-item label="事件类别">{{info.eventCategory}}</el-descriptions-item>
                <el-descriptions-item label="释放操作人">{{info.releaseBlackUser}}</el-descriptions-item>
                <el-descriptions-item label="禁止时长">{{info.blackDay}}天</el-descriptions-item>
                <el-descriptions-item label="拉黑条件">{{info.blackRule == 0 ? '累计': (info.blackRule == 90 ? '近3月':
                    (info.blackRule == 180 ? '近半年' : (info.blackRule == 365 ? '近1年' : '')))}} {{info.blackNums}}次
                </el-descriptions-item>
            </el-descriptions>
            <div class="p_t2 p_b1 fontSize16">爽约记录</div>
            <el-table :data="info.ticketingBlackDetailVoList" border size="small" style="width: 100%">
                <el-table-column prop="ticketingValidationCode" label="票号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="makeDate" label="参观时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="storehouseTime" label="入馆时段" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorState" label="参观状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("visitor_state",scope.row.ticketingVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="breakAppointmentTime" label="爽约时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outTradeNo" label="订单编号" show-overflow-tooltip></el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                id: null,
                info: {},
            }
        },
        methods: {
            init(row) {
                this.id = row.id
                this.visible = true
                this.getData();
            },
            // 获取数据
            getData() {
                this.$axios(this.api.ticketing.sysGetTicketingBlackById, {id: this.id}).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
