import { render, staticRenderFns } from "./blackDetails.vue?vue&type=template&id=29956da8&scoped=true&"
import script from "./blackDetails.vue?vue&type=script&lang=js&"
export * from "./blackDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29956da8",
  null
  
)

export default component.exports